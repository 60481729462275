import {
    CursorEffects,
    ScrollTriggerClass,
} from './_normal'

{
    const domCursorEffects = document.querySelectorAll('[data-js="effect"]');
    if (domCursorEffects) {
        [...domCursorEffects].forEach(el=>{
            new CursorEffects(el);
        })
    }

    const domScrollTriggerClass = document.querySelectorAll('[data-trigger="stc"]');
    if (domScrollTriggerClass) new ScrollTriggerClass(domScrollTriggerClass);  
}