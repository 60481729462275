import { gsap, Power1, Power4 } from 'gsap';

class CursorEffects {
  constructor(el) {
    this.dom = {el: el};
    this.dom.target = this.dom.el.querySelectorAll('[data-js="effect-target"]');

    this.sy = 0;
    this.sy_after = 0;
    this.sy_diff = null;
    this.sy_diff_max = 250;
    
    this.clones = [];

    this.moving = this.moving.bind(this);

    this.init();
  }

  init() {
    const _this = this;

    [...this.dom.target].forEach(el=>{
      this.clones = [];
      for (let i = 0; i < 5; i++) {
        this.clones.push(el.cloneNode(true));
        el.parentNode.appendChild(this.clones[i]);
      }

      if (window.innerWidth >= 768) {
        el.addEventListener('mousemove', (e)=>{
          _this.moving(e, this.clones);
        })
  
        el.addEventListener('mouseleave', (e)=>{
          _this.reset(this.clones);
        })
      }
      
      window.addEventListener('scroll', (e) => {
        _this.scroll(e, el);
      })
    })
  }

  scroll(e, el) {
    this.sy = window.scrollY;

    this.sy_diff = (this.sy_after) ? this.sy - this.sy_after : this.sy - 0;
    console.log('this.sy_diff', this.sy_diff)
    this.afterimage(this.sy_diff, this.clones);

    this.sy_after = this.sy;

    // const rect = el.getBoundingClientRect();
    // console.log(rect.y);
    // const rectHarfY = (rect.height * .5);
    // const posY = (rectHarfY - sy) * .01;  
    // console.log({posY})

    // gsap.to(el, {
    //   duration: .9,
    //   opacity: .225 - (.025 * i),
    //   x: posX * (1.4 * i),
    //   y: posY * (1.4 * i),
    //   rotationY: posX * (.1 * i),
    //   rotationX: posX * (.1 * i),
    //   scale: 1 + (.015 * i),
    //   ease: 'Power4.inOut'
    // })
  }

  afterimage(diff, clones) {
    const _this = this;

    const move = (diff > this.sy_diff_max) ? this.sy_diff_max : (diff < -this.sy_diff_max) ? -this.sy_diff_max : diff;
    console.log({move})

    clones.forEach((el, i)=>{
      gsap.to(el, {
        duration: .3,
        opacity: .2 - (.025 * i),
        y: move * (1 * i),
        ease: 'Power1.in',
        onComplete: () => {
          _this.afterimageReset(this.clones);
        }
      })
    })
  }

  afterimageReset(clones) {
    clones.forEach((el, i)=>{
      gsap.to(el, {
        duration: .9,
        opacity: 0,
        ease: 'Power1.out',
      })
      gsap.to(el, {
        duration: .15,
        y: 0,
        ease: 'Power1.out',
        onComplete: () => {
          gsap.to(el, {
            y: 0,
          })
        }
      })
    })
  }

  moving(e, clones) {
    const canvasSize = {
      w: window.innerWidth,
      h: window.innerHeight,
    };    

    clones.forEach((el, i)=>{
      const rect = el.getBoundingClientRect();
      const rectHarfX = (rect.width * .5);
      const rectHarfY = (rect.height * .5);
      const posX = (rectHarfX - e.offsetX) * .01;
      const posY = (rectHarfY - e.offsetY) * .01;  

      gsap.to(el, {
        duration: .9,
        opacity: .225 - (.025 * i),
        x: posX * (1.4 * i),
        y: posY * (1.4 * i),
        rotationY: posX * (.1 * i),
        rotationX: posX * (.1 * i),
        scale: 1 + (.015 * i),
        ease: 'Power4.inOut'
      })
    })
  }

  reset(clones) {
    clones.forEach((el, i)=>{
      gsap.to(el, {
        duration: 1.2,
        opacity: 0,
        ease: 'Power4.inOut',
        onComplete: () => {
          gsap.to(el, {
            x: 0,
            y: 0,
            rotationY: 0,
            rotationX: 0,
            scale: 1,    
          })
        }
      })
    })
  }
}

export default CursorEffects